// Example static data. In a real app, fetch this from an API.
export const artists = [
    {
        id: 'artist-1',
        name: 'JP Solis',
        socials: {
            instagram: 'https://instagram.com/jpsolis.nyc',
            bandcamp: 'https://jpsolis.bandcamp.com',
            soundcloud: 'https://soundcloud.com/jpsolis808',
        },
        selectedDate: '2024-12-01',
        bio: `DJ CoolVibes is known for their energetic sets blending hip-hop, house, and funk. They have performed at major festivals worldwide and released chart-topping remixes.`,
        youtubeLinks: [
            'https://www.youtube.com/embed/loaaPXFfhdI',
            'https://www.youtube.com/embed/VIDEO_ID_2'
        ],
        thumbnailUrl: 'https://example.com/thumbnail.jpg'
    },
    {
        id: 'artist-2',
        name: 'WITNESS PROTECTION',
        socials: {
            instagram: 'https://instagram.com/mcdeepbeats',
            twitter: 'https://twitter.com/mcdeepbeats',
        },
        selectedDate: '2024-12-07',
        bio: `MC DeepBeats is a rising star in the underground scene, known for hypnotic techno sets and collaborations with top producers.`,
        youtubeLinks: [
            'https://www.youtube.com/embed/IVrmp55jHUo',
            'https://www.youtube.com/embed/sUYWGFp3bk0',
        ],
        thumbnailUrl: 'https://example.com/thumbnail.jpg'
    }
];
