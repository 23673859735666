import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import "./App.css";
import Nav from "./Nav/Nav";
import HomeContent from "./HomeContent/HomeContent";
import About from "./About/About";
import Shop from "./Shop/Shop";
import Radio from "./Radio/Radio";
import ArtistSpotlightList from "./ArtistSpotlight/ArtistSpotlight";
import ArtistDetail from "./ArtistSpotlight/pages/ArtistDetail";
import { PlaylistsPage, PlaylistPage } from "./PlaylistPage/PlaylistPage";
import SoundCloudPlayer from "./SoundCloudPlayer/SoundCloudPlayer";
import { AudioPlayerProvider } from "./contexts/AudioPlayerContext";

const AnimatedRoutes = ({ setIsPDP }) => {
  const location = useLocation();
  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={location.pathname}
        initial={{ filter: "blur(8px)", opacity: 0 }}
        animate={{ filter: "blur(0px)", opacity: 1 }}
        exit={{ filter: "blur(8px)", opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <Routes location={location}>
          <Route path="/" element={<HomeContent />} />
          <Route path="/about" element={<About />} />
          <Route path="/shop/*" element={<Shop setIsPDP={setIsPDP} />} />
          <Route path="/radio" element={<Radio />} />
          <Route path="/playlists" element={<PlaylistsPage />} />
          <Route path="/playlist/:name" element={<PlaylistPage />} />
          <Route path="/artistspotlight" element={<ArtistSpotlightList />} />
          <Route path="/artists/:artistId" element={<ArtistDetail />} />
        </Routes>
      </motion.div>
    </AnimatePresence>
  );
};

function App() {
  const [isPDP, setIsPDP] = useState(false);
  useEffect(() => {
    if (isPDP) {
      document.body.classList.add('pdp-open');
    } else {
      document.body.classList.remove('pdp-open');
    }
  }, [isPDP]);
  return (
    <AudioPlayerProvider>
      <iframe
        id="soundcloud-iframe"
        width="100%"
        height="166"
        scrolling="no"
        frameBorder="no"
        allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//soundcloud.com/xxwavyxx/sets/gang-coolin&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
        style={{ display: "none" }}
      ></iframe>
      <SoundCloudPlayer />
      <Router>
        <div className="App">
          <Nav />
          <main className="App-content">
            <AnimatedRoutes setIsPDP={setIsPDP} />
          </main>
          {!isPDP && (
            <footer className="shop-footer">
              <p>&copy; 2024 Spinnin' Beans. All rights reserved.</p>
            </footer>
          )}
        </div>
      </Router>
    </AudioPlayerProvider>
  );
}

export default App;
