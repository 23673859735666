import React, { useState, useRef, useEffect, useCallback } from "react";
import { useAudioPlayer } from "../contexts/AudioPlayerContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBackward,
  faForward,
  faPlay,
  faPause,
  faListUl,
} from "@fortawesome/free-solid-svg-icons";
import "./SoundCloudPlayer.scss";

const ThinListIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="3"
      y1="4.5"
      x2="15"
      y2="4.5"
      stroke="currentColor"
      strokeWidth="1"
    />
    <line
      x1="3"
      y1="9.5"
      x2="15"
      y2="9.5"
      stroke="currentColor"
      strokeWidth="1"
    />
    <line
      x1="3"
      y1="14.5"
      x2="15"
      y2="14.5"
      stroke="currentColor"
      strokeWidth="1"
    />
  </svg>
);

const SoundCloudPlayer = () => {
  const {
    isExpanded,
    setIsExpanded,
    isPlaying,
    setIsPlaying,
    currentTrack,
    togglePlayPause,
    skipTrack,
    setVolume: setGlobalVolume,
    widgetRef,
    loadPlaylist,
  } = useAudioPlayer();

  const [localVolume, setLocalVolume] = useState(50);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [showTrackList, setShowTrackList] = useState(false);
  const [isWidgetReady, setIsWidgetReady] = useState(false);
  const [waveformData, setWaveformData] = useState(Array(100).fill(0.5));
  const prevWaveformData = useRef(Array(100).fill(0.5));
  const [isSeeking, setIsSeeking] = useState(false);
  const seekTimeoutRef = useRef(null);
  const progressBarRef = useRef(null);
  const animationRef = useRef(null);
  const [showPlaylistSelector, setShowPlaylistSelector] = useState(false);
  const [isActionReady, setIsActionReady] = useState(true);
  const cooldownPeriod = 500; // milliseconds
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [totalTracks, setTotalTracks] = useState(0);

  const throttleAction = useCallback(
    (action) => {
      if (isActionReady) {
        setIsActionReady(false);
        action();
        setTimeout(() => setIsActionReady(true), cooldownPeriod);
      }
    },
    [isActionReady]
  );

  const seekTo = useCallback(
    (position) => {
      if (widgetRef.current) {
        widgetRef.current.seekTo(position);
        setCurrentTime(position);
      }
    },
    [widgetRef]
  );

  const handleSeekStart = () => {
    setIsSeeking(true);
    if (widgetRef.current) {
      widgetRef.current.pause();
    }
  };

  const handleSeekEnd = () => {
    setIsSeeking(false);
    if (widgetRef.current) {
      widgetRef.current.play();
    }
  };

  const handleSeek = useCallback(
    (e) => {
      const progressBar = progressBarRef.current;
      if (!progressBar) return;

      const rect = progressBar.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const clickPosition = (x / rect.width) * duration;

      seekTo(clickPosition);
    },
    [duration, seekTo]
  );

  const handleScrub = useCallback(
    (e) => {
      const newPosition = parseInt(e.target.value);
      setCurrentTime(newPosition);
      seekTo(newPosition);
    },
    [seekTo]
  );

  const generateWaveform = useCallback(() => {
    if (!isPlaying) return;

    const targetData = Array.from(
      { length: 100 },
      () => Math.random() * 0.8 + 0.2
    );

    const newData = waveformData.map((current, index) => {
      const target = targetData[index];
      const prev = prevWaveformData.current[index];
      return current + (target - prev) * 0.1;
    });

    setWaveformData(newData);
    prevWaveformData.current = newData;

    animationRef.current = requestAnimationFrame(generateWaveform);
  }, [isPlaying, waveformData]);

  useEffect(() => {
    if (isPlaying) {
      animationRef.current = requestAnimationFrame(generateWaveform);
    } else {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    }

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [isPlaying, generateWaveform]);

  const formatTime = useCallback((ms) => {
    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  }, []);

  useEffect(() => {
    if (widgetRef.current) {
      widgetRef.current.bind(window.SC.Widget.Events.READY, () => {
        setIsWidgetReady(true);
        widgetRef.current.getDuration((d) => setDuration(d));
        widgetRef.current.getSounds((sounds) => setTotalTracks(sounds.length));
      });
    }
    console.log(totalTracks);
  }, [widgetRef]);


  useEffect(() => {
    if (widgetRef.current && currentTrack) {
      widgetRef.current.getDuration((d) => setDuration(d));
      widgetRef.current.getPosition((p) => setCurrentTime(p));
      widgetRef.current.getCurrentSoundIndex((index) => setCurrentTrackIndex(index));
      widgetRef.current.getSounds((sounds) => setTotalTracks(sounds.length));
      setIsLoading(false);
    }
  }, [currentTrack, widgetRef]);

  const togglePlayer = () => setIsExpanded(!isExpanded);

  // Define your playlists here
  const playlists = [
    {
      name: "Gang Coolin",
      url: "https://soundcloud.com/xxwavyxx/sets/gang-coolin",
    },
    {
      name: "Jazz Fi",
      url: "https://soundcloud.com/xxwavyxx/sets/jazz-fi",
    },
    // Add more playlists as needed
  ];

  const [currentPlaylist, setCurrentPlaylist] = useState(playlists[0]);

  const [isLoading, setIsLoading] = useState(false);

  const handlePlaylistChange = (playlistUrl) => {
    const selectedPlaylist = playlists.find(playlist => playlist.url === playlistUrl);
    if (selectedPlaylist) {
      setCurrentPlaylist(selectedPlaylist);
      setIsLoading(true);

      // Stop the current playback and load the new playlist
      loadPlaylist(playlistUrl);

      // Reset local state
      setCurrentTime(0);
      setDuration(0);
      setShowTrackList(false);
      setCurrentTrackIndex(0); // Reset the track index
      updateTotalTracks(); // Update the total number of tracks
    }
  };

  const handleVolumeChange = useCallback(
    (e) => {
      const newVolume = parseInt(e.target.value);
      setLocalVolume(newVolume);
      if (widgetRef.current) {
        widgetRef.current.setVolume(newVolume);
      }
    },
    [widgetRef, setLocalVolume]
  );

  const handlePrevTrack = () => {
    throttleAction(() => {
      if (widgetRef.current && currentTrackIndex > 0) {
        widgetRef.current.prev();
      }
    });
  };

  const handleNextTrack = () => {
    throttleAction(() => {
      if (widgetRef.current && currentTrackIndex < totalTracks - 1) {
        widgetRef.current.next();
      }
    });
  };

  const toggleTrackList = () => {
    setShowTrackList(!showTrackList);
  };

  const handleTrackSelect = (trackId) => {
    skipTrack(trackId);
    setShowTrackList(false);
  };

  const updateTotalTracks = useCallback(() => {
    if (widgetRef.current) {
      widgetRef.current.getSounds((sounds) => {
        setTotalTracks(sounds.length);
      });
    }
  }, [widgetRef]);

  useEffect(() => {
    if (widgetRef.current) {
      const handlePlayProgress = (event) => {
        setCurrentTime(event.currentPosition);
      };

      widgetRef.current.bind(window.SC.Widget.Events.PLAY_PROGRESS, handlePlayProgress);

      return () => {
        widgetRef.current.unbind(window.SC.Widget.Events.PLAY_PROGRESS, handlePlayProgress);
      };
    }
  }, [widgetRef]);

  return (
    <div className={`soundcloud-player ${isExpanded ? "expanded" : ""}`}>
      {isExpanded ? (
        <div
          className={`player-container ${showTrackList ? "playlist-open" : ""}`}
        >
          {!showTrackList && !isLoading && (
            <button className="close-button" onClick={togglePlayer}>
              ×
            </button>
          )}
          <div className={`custom-player ${showTrackList ? "blurred" : ""} ${isLoading ? "loading" : ""}`}>
            <div className="track-info">
              <h3>{currentTrack?.title || "Loading..."}</h3>
              <p>{currentTrack?.user?.username || ""}</p>
              <p>Playlist: {currentPlaylist.name}</p>
            </div>
            <div className="progress-container" ref={progressBarRef}>
              <input
                type="range"
                min="0"
                max={duration}
                value={currentTime}
                onChange={handleScrub}
                onMouseDown={handleSeekStart}
                onMouseUp={handleSeekEnd}
                onTouchStart={handleSeekStart}
                onTouchEnd={handleSeekEnd}
                className="progress-bar"
              />
              <div className="time-display">
                <span>{formatTime(currentTime)}</span>
                <span>{formatTime(duration)}</span>
              </div>
            </div>
            <div className="controls">
              <button
                onClick={handlePrevTrack}
                disabled={!isActionReady || currentTrackIndex === 0}
              >
                <FontAwesomeIcon icon={faBackward} />
              </button>
              <button onClick={togglePlayPause} disabled={!isActionReady}>
                <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
              </button>
              <button
                onClick={handleNextTrack}
                disabled={!isActionReady || (currentTrackIndex >= totalTracks - 1)}
              >
                <FontAwesomeIcon icon={faForward} />
              </button>
              <button
                className="playlist-toggle"
                onClick={toggleTrackList}
                disabled={!isActionReady}
              >
                <FontAwesomeIcon icon={faListUl} />
              </button>
            </div>
            <div className="volume-control">
              <input
                type="range"
                min="0"
                max="100"
                value={localVolume}
                onChange={handleVolumeChange}
                className="volume-slider"
              />
              <div
                className="waveform-container"
                style={{ opacity: isPlaying ? 1 : 0.5 }}
              >
                {waveformData.map((height, index) => (
                  <div
                    key={index}
                    className="waveform-bar"
                    style={{
                      height: `${height * 100}%`,
                      opacity: localVolume / 100,
                    }}
                  ></div>
                ))}
              </div>
            </div>
          </div>
          <div className={`loading-overlay ${isLoading ? "visible" : ""}`}>
            <div className="loading-spinner"></div>
          </div>
          {showTrackList && (
            <div className="tracklist-overlay">
              <h3>Playlists</h3>
              {playlists.length > 0 ? (
                <ul>
                  {playlists.map((playlist) => (
                    <li
                      key={playlist.url}
                      onClick={() => handlePlaylistChange(playlist.url)}
                    >
                      {playlist.name}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No valid playlists available</p>
              )}
              <button className="close-tracklist" onClick={toggleTrackList}>
                Close
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className="button-container">
          <div className="hover-text">
            {isPlaying && currentTrack
              ? `Now Playing: ${currentTrack.title}`
              : "Our Mixes"}
          </div>
          <button
            className={`expand-button ${isPlaying ? 'playing' : ''}`}
            onClick={togglePlayer}
          >
            <img src="/assets/soundcloud.png" alt="Expand Player" />
          </button>
        </div>
      )}
    </div>
  );
};

export default SoundCloudPlayer;
