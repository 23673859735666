import "./About.scss";

const About = () => {
  return (
    <div className="aboutContainer">
      <div className="aboutContent">
        <div className="scrollContent">
          <h1>Welcome to Spinnin' Beans</h1>
          <p>
            At Spinnin' Beans, coffee and music are not just part of our
            business—they are part of our soul. Located in the vibrant heart of
            Denver, Colorado, our café is a sanctuary for those who cherish the
            rich aromas of expertly crafted coffee and the pulsating rhythms of
            curated playlists.
          </p>

          <h2>Our Coffee</h2>
          <p>
            Every bean is sourced directly from local Boulder, Colorado,
            roasters, renowned for their commitment to sustainability and
            quality. We carefully select our partners based on their dedication
            to ethical practices and their prowess in roasting beans that
            capture the complex flavors of their origins. At Spinnin' Beans,
            every cup serves as a testament to the rich coffee culture of
            Colorado, offering our patrons a taste that's both local and
            luxuriously global.
          </p>

          <h2>Our Music</h2>
          <p>
            In our space, music is as essential as the coffee. The playlists you
            hear are curated with the same care and precision as our coffee
            selection. From the deep grooves of house to the upbeat rhythms of
            funky, to the resonant vibes of future bass, we celebrate all genres
            that make you feel good. Our live DJ sessions, featuring both
            up-and-coming and renowned artists, bring the community together,
            providing a backdrop for both relaxation and celebration. These
            sessions are more than just music; they are communal experiences
            that uplift and unite.
          </p>

          <h2>Our Mission</h2>
          <p>
            At Spinnin' Beans, our mission is simple: to make every visit—and
            every listen—a memorable experience where energy and music come
            together to create a perfect moment. We are dedicated to providing
            intimate, euphoric experiences through intimate sets from our
            favorite DJs, crafting moments of joy and connection.
          </p>

          <h2>Join Us</h2>
          <p>
            We invite you to join us at Spinnin' Beans, where the coffee is
            always brewing and the beats never stop. Whether you're looking to
            kickstart your morning with a robust espresso or unwind after a long
            day with a soothing latte and some smooth tunes, you'll find your
            spot here. Spinnin' Beans isn't just a café; it's a lifestyle, a
            community, and a haven for coffee lovers and music aficionados
            alike.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
