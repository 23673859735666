import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { artists } from './data/artists';
import styles from './ArtistSpotlight.module.scss';

const ArtistSpotlightList = () => {
  return (
    <div className={styles.listContainer}>
      <h1 className={styles.pageTitle}>Artist Spotlights</h1>
      <div className={styles.artistList}>
        {artists.map(artist => (
          <Link
            key={artist.id}
            to={`/artists/${artist.id}`}
            className={styles.artistLink}
          >
            <ArtistListItem artist={artist} />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ArtistSpotlightList;

export const ArtistListItem = ({ artist }) => {
  const [thumbUrl, setThumbUrl] = useState('#222'); // default fallback

  useEffect(() => {
    if (artist.youtubeLinks && artist.youtubeLinks.length > 0) {
      const videoID = extractYouTubeID(artist.youtubeLinks[0]);
      if (videoID) {
        const maxResURL = `https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`;
        const img = new Image();
        img.src = maxResURL;
        img.onload = () => {
          // If maxres loads, use it
          setThumbUrl(maxResURL);
        };
        img.onerror = () => {
          // If maxres fails, fallback to hqdefault
          setThumbUrl(`https://img.youtube.com/vi/${videoID}/hqdefault.jpg`);
        };
      }
    }
  }, [artist]);

  const backgroundStyle =
    thumbUrl && thumbUrl !== '#222'
      ? {
        background: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${thumbUrl}) center/cover no-repeat`
      }
      : { background: '#222' };

  return (
    <div className={styles.artistRow} style={backgroundStyle}>
      <div className={styles.artistInfo}>
        <h2 className={styles.artistName}>{artist.name}</h2>
      </div>
      <div className={styles.selectedDate}>
        <span>{artist.selectedDate}</span>
      </div>
    </div>
  );
};

function extractYouTubeID(url) {
  const regex = /youtube\.com\/embed\/([^?]+)/;
  const match = url.match(regex);
  return match && match[1] ? match[1] : null;
}
