import React, {
  createContext,
  useState,
  useContext,
  useRef,
  useEffect,
  useCallback,
} from "react";

const AudioPlayerContext = createContext();

export const AudioPlayerProvider = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTrack, setCurrentTrack] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const widgetRef = useRef(null);

  const updateCurrentTrack = useCallback(() => {
    if (widgetRef.current) {
      widgetRef.current.getCurrentSound((sound) => {
        if (sound) {
          setCurrentTrack(sound);
          widgetRef.current.getDuration((d) => setDuration(d));
        }
      });
    }
  }, []);

  useEffect(() => {
    const widget = window.SC.Widget("soundcloud-iframe");
    widgetRef.current = widget;

    const updateCurrentTime = () => {
      widget.getPosition((p) => setCurrentTime(p));
    };

    let progressInterval;

    const handlePlay = () => {
      setIsPlaying(true);
      updateCurrentTrack();
      // Start interval when playback begins
      progressInterval = setInterval(updateCurrentTime, 100);
    };

    const handlePause = () => {
      setIsPlaying(false);
      // Clear interval when playback is paused
      clearInterval(progressInterval);
    };

    const handleReady = () => {
      loadPlaylist("https://soundcloud.com/xxwavyxx/sets/gang-coolin");
    };

    widget.bind(window.SC.Widget.Events.PLAY, handlePlay);
    widget.bind(window.SC.Widget.Events.PAUSE, handlePause);
    widget.bind(window.SC.Widget.Events.READY, handleReady);

    return () => {
      widget.unbind(window.SC.Widget.Events.PLAY, handlePlay);
      widget.unbind(window.SC.Widget.Events.PAUSE, handlePause);
      widget.unbind(window.SC.Widget.Events.READY, handleReady);
      clearInterval(progressInterval);
    };
  }, [updateCurrentTrack]);

  const loadPlaylist = useCallback((playlistUrl) => {
    if (widgetRef.current) {
      // Stop the current playback
      widgetRef.current.pause();

      // Reset the current track and playback state
      setCurrentTrack(null);
      setIsPlaying(false);

      // Load the new playlist
      widgetRef.current.load(playlistUrl, {
        auto_play: false,
        callback: () => {
          // This callback is called when the new playlist is loaded
          updateCurrentTrack();
          // Ensure the player is paused after loading
          widgetRef.current.pause();
          setIsPlaying(false);
        }
      });
    }
  }, [updateCurrentTrack]);

  const togglePlayPause = useCallback(() => {
    if (widgetRef.current) {
      if (isPlaying) {
        widgetRef.current.pause();
      } else {
        widgetRef.current.play();
      }
    }
  }, [isPlaying]);

  const skipTrack = useCallback((direction) => {
    if (widgetRef.current) {
      if (direction === "next") {
        widgetRef.current.next();
      } else if (direction === "prev") {
        widgetRef.current.prev();
      }
    }
  }, []);

  const setVolume = useCallback((volume) => {
    if (widgetRef.current) {
      widgetRef.current.setVolume(volume);
    }
  }, []);

  return (
    <AudioPlayerContext.Provider
      value={{
        isExpanded,
        setIsExpanded,
        isPlaying,
        currentTrack,
        currentTime,
        duration,
        togglePlayPause,
        skipTrack,
        setVolume,
        widgetRef,
        loadPlaylist,
        updateCurrentTrack,
      }}
    >
      {children}
    </AudioPlayerContext.Provider>
  );
};

export const useAudioPlayer = () => useContext(AudioPlayerContext);
