import React, { useState, useEffect } from "react";
import "./Radio.scss";
import AWS from "aws-sdk";
import Modal from "react-modal";

const Radio = () => {
  const [recordings, setRecordings] = useState([]);
  const [selectedRecording, setSelectedRecording] = useState(null);
  const [upcomingSets, setUpcomingSets] = useState([]);

  // Function to fetch recordings from S3
  const fetchRecordings = async () => {
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    const params = {
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      Prefix: "recordings/", // Adjust the prefix as needed
    };

    try {
      const data = await s3.listObjectsV2(params).promise();
      const s3Recordings = data.Contents.map((item) => ({
        key: item.Key,
        url: `https://${params.Bucket}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${item.Key}`,
      }));

      // Combine S3 recordings with mock recordings
      const mockRecordings = [
        {
          id: 'mock1',
          title: "Late Night Grooves",
          date: "2023-05-20",
          duration: "120",
          genre: "Deep House",
          url: "https://example.com/recordings/late-night-grooves.mp4"
        },
        {
          id: 'mock2',
          title: "Sunday Chill Session",
          date: "2023-05-14",
          duration: "90",
          genre: "Ambient",
          url: "https://example.com/recordings/sunday-chill-session.mp4"
        },
        // Add more mock recordings as needed
      ];

      setRecordings([...s3Recordings, ...mockRecordings]);
    } catch (error) {
      console.error("Error fetching recordings from S3:", error);
    }
  };

  useEffect(() => {
    fetchRecordings();
    // Fetch upcoming sets (placeholder for now)
    setUpcomingSets([
      {
        id: 1,
        thumbnail: "https://picsum.photos/200/200?random=1",
        title: "Summer Vibes Mix",
        date: "2023-06-15",
        length: "120",
        genre: "House"
      },
      {
        id: 2,
        thumbnail: "https://picsum.photos/200/200?random=2",
        title: "Chill Beats Session",
        date: "2023-06-22",
        length: "90",
        genre: "Lo-fi"
      },
      {
        id: 3,
        thumbnail: "https://picsum.photos/200/200?random=3",
        title: "Electronic Dance Party",
        date: "2023-06-29",
        length: "150",
        genre: "EDM"
      },
      // Add more sets to test scrolling
    ]);
  }, []);

  const openModal = (recording) => {
    setSelectedRecording(recording);
  };

  const closeModal = () => {
    setSelectedRecording(null);
  };

  return (
    <div className="radio-container">
      <h1>Spinnin' Beans Radio</h1>

      <section className="upcoming-sets">
        <h2>Upcoming DJ Sets</h2>
        <div className="set-list">
          {upcomingSets.map((set) => (
            <div key={set.id} className="set-card">
              <img src={set.thumbnail} alt={set.title} className="set-thumbnail" />
              <div className="set-info">
                <h3>{set.title}</h3>
                <p>Date: {set.date}</p>
                <p>Length: {set.length} minutes</p>
                <p>Genre: {set.genre}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="recordings-list">
        <h2>Past Recordings</h2>
        <ul>
          {recordings.map((recording, index) => (
            <li key={recording.id || index} onClick={() => openModal(recording)}>
              {recording.title || recording.key.replace("recordings/", "")}
              {recording.date && <p>Date: {recording.date}</p>}
              {recording.duration && <p>Duration: {recording.duration} minutes</p>}
              {recording.genre && <p>Genre: {recording.genre}</p>}
            </li>
          ))}
        </ul>
      </section>

      {selectedRecording && (
        <Modal
          isOpen={!!selectedRecording}
          onRequestClose={closeModal}
          contentLabel="Recording Player"
          className="modal"
          overlayClassName="overlay"
        >
          <button onClick={closeModal} className="close-button">
            Close
          </button>
          <video controls>
            <source src={selectedRecording.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Modal>
      )}
    </div>
  );
};

export default Radio;
