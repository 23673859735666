import "./HomeContent.scss";

const HomeContent = () => {
  return (
    <div className="home-content">
      <div className="home-text-container">
        <div className="home-text-main">
          <h1>Hi, We're Spinnin' Beans</h1>
          <p>
            We are a music discovery platform celebrating the freshest sounds and timeless classics in dance, house, garage, and beyond. From emerging artists to the pioneers who shaped these genres, we bring you closer to the music that moves you. Join us in keeping the rhythm alive and the vibes soulful.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomeContent;
