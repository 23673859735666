import React from "react";
import { useParams, Link } from "react-router-dom";
import "./PlaylistPage.scss";

const PlaylistPage = () => {
  const { name } = useParams();

  // In a real app, you'd fetch the playlist data based on the name
  // For now, we'll use some mock data
  const playlist = {
    name: name.replace(/-/g, " "),
    description: "This is a sample description for the playlist.",
    tracks: [
      { id: 1, title: "Track 1", artist: "Artist 1" },
      { id: 2, title: "Track 2", artist: "Artist 2" },
      { id: 3, title: "Track 3", artist: "Artist 3" },
    ],
  };

  return (
    <div className="playlist-page">
      <h1>{playlist.name}</h1>
      <p>{playlist.description}</p>
      <h2>Tracks</h2>
      <ul className="track-list">
        {playlist.tracks.map((track) => (
          <li key={track.id}>
            {track.title} - {track.artist}
          </li>
        ))}
      </ul>
      <Link to="/playlists" className="back-link">
        Back to Playlists
      </Link>
    </div>
  );
};

const PlaylistCard = ({ name, image, description }) => {
  return (
    <Link
      to={`/playlist/${name.toLowerCase().replace(/\s+/g, "-")}`}
      className="playlist-card"
    >
      <img src={image} alt={name} className="playlist-image" />
      <div className="playlist-info">
        <h3>{name}</h3>
        <p>{description}</p>
      </div>
    </Link>
  );
};

const PlaylistsPage = () => {
  const testPlaylists = [
    {
      name: "Chill Beats",
      image: "https://picsum.photos/200/200?random=1",
      description: "Relaxing beats to unwind to",
    },
    {
      name: "Coffee House Vibes",
      image: "https://picsum.photos/200/200?random=2",
      description: "Perfect soundtrack for your coffee break",
    },
    {
      name: "Energetic Mornings",
      image: "https://picsum.photos/200/200?random=3",
      description: "Start your day with these upbeat tunes",
    },
    {
      name: "Late Night Jazz",
      image: "https://picsum.photos/200/200?random=4",
      description: "Smooth jazz for those late night sessions",
    },
  ];

  return (
    <div className="playlists-page">
      <h1>What's Spinnin?</h1>
      <div className="playlist-grid">
        {testPlaylists.map((playlist, index) => (
          <PlaylistCard key={index} {...playlist} />
        ))}
      </div>
    </div>
  );
};

export { PlaylistsPage, PlaylistPage };
