import React, { useState, useRef, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link, useParams, useNavigate, useLocation } from "react-router-dom";
import "./Shop.scss";

const Shop = ({ setIsPDP }) => {
  const location = useLocation();

  useEffect(() => {
    setIsPDP(location.pathname.includes('/product/'));
  }, [location, setIsPDP]);

  // Mock product data
  const allProducts = [
    {
      id: 1,
      name: "Coffee Blend A",
      price: 12.99,
      image: "path_to_image_1.jpg",
      category: "coffee",
    },
    {
      id: 2,
      name: "Coffee Blend B",
      price: 14.99,
      image: "path_to_image_2.jpg",
      category: "coffee",
    },
    {
      id: 3,
      name: "Coffee Grinder",
      price: 49.99,
      image: "path_to_image_3.jpg",
      category: "equipment",
    },
    {
      id: 4,
      name: "Spinnin' Beans T-Shirt",
      price: 24.99,
      image: "path_to_image_4.jpg",
      category: "merchandise",
    },
    {
      id: 5,
      name: "Coffee Blend C",
      price: 15.99,
      image: "path_to_image_5.jpg",
      category: "coffee",
    },
    // Add more products as needed
  ];

  const [activeFilter, setActiveFilter] = useState("all");
  const [products, setProducts] = useState(allProducts);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isProductDetailVisible, setIsProductDetailVisible] = useState(false);

  const handleFilter = (category) => {
    setActiveFilter(category);
    if (category === "all") {
      setProducts(allProducts);
    } else {
      const filteredProducts = allProducts.filter(
        (product) => product.category === category
      );
      setProducts(filteredProducts);
    }
  };

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
    setIsTransitioning(true);
    // Delay setting visibility to allow for the blur to start first
    setTimeout(() => {
      setIsProductDetailVisible(true);
    }, 50);
  };

  const handleCloseProductDetail = () => {
    setIsProductDetailVisible(false);
    setIsTransitioning(true);
    setTimeout(() => {
      setSelectedProduct(null);
      setIsTransitioning(false);
    }, 400); // Match this with the transition duration in CSS
  };

  useEffect(() => {
    document.body.style.overflow = selectedProduct ? 'hidden' : 'visible';
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [selectedProduct]);

  return (
    <div className="shop">
      <header className="shop-header">
        <h1>Spinnin' Beans Shop</h1>
        <nav>
          <ul>
            <li>
              <a
                href="#all"
                onClick={() => handleFilter("all")}
                className={activeFilter === "all" ? "active" : ""}
              >
                All
              </a>
            </li>
            <li>
              <a
                href="#coffee"
                onClick={() => handleFilter("coffee")}
                className={activeFilter === "coffee" ? "active" : ""}
              >
                Coffee
              </a>
            </li>
            <li>
              <a
                href="#equipment"
                onClick={() => handleFilter("equipment")}
                className={activeFilter === "equipment" ? "active" : ""}
              >
                Equipment
              </a>
            </li>
            <li>
              <a
                href="#merchandise"
                onClick={() => handleFilter("merchandise")}
                className={activeFilter === "merchandise" ? "active" : ""}
              >
                Merchandise
              </a>
            </li>
          </ul>
        </nav>
      </header>

      <main className="shop-main">
        <Routes>
          <Route path="/" element={
            <section className="product-grid">
              {products.map((product) => (
                <Link key={product.id} to={`product/${product.id}`} className="product-card">
                  <img src={product.image} alt={product.name} />
                  <h3>{product.name}</h3>
                  <p>${product.price.toFixed(2)}</p>
                  <button onClick={(e) => { e.preventDefault(); }}>Add to Cart</button>
                </Link>
              ))}
            </section>
          } />
          <Route path="product/:id" element={<ProductDetail products={allProducts} />} />
        </Routes>
      </main>
    </div>
  );
};

const ProductDetail = ({ products }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const imagesRef = useRef(null);
  const contentRef = useRef(null);

  const product = products.find(p => p.id === parseInt(id));

  useEffect(() => {
    const handleScroll = (e) => {
      const imagesElement = imagesRef.current;
      const contentElement = contentRef.current;

      if (imagesElement && contentElement) {
        const imagesBottom = imagesElement.getBoundingClientRect().bottom;
        const windowHeight = window.innerHeight;

        if (imagesBottom > windowHeight) {
          e.preventDefault();
          imagesElement.scrollTop += e.deltaY;
        } else {
          contentElement.scrollTop += e.deltaY;
        }
      }
    };

    const handleTouchMove = (e) => {
      const touch = e.touches[0];
      handleScroll({ deltaY: touch.pageY });
    };

    window.addEventListener('wheel', handleScroll, { passive: false });
    window.addEventListener('touchmove', handleTouchMove, { passive: false });

    return () => {
      window.removeEventListener('wheel', handleScroll);
      window.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  if (!product) return <div>Product not found</div>;

  // Mock multiple images for the product
  const productImages = [
    'https://picsum.photos/200/300?random=1',
    'https://picsum.photos/200/300?random=2',
    'https://picsum.photos/200/300?random=3',
    'https://picsum.photos/200/300?random=4',
  ];

  return (
    <div className="product-detail">
      <button className="close-button" onClick={() => navigate('/shop')}>×</button>
      <div className="product-content">
        <div className="product-images" ref={imagesRef}>
          {productImages.map((img, index) => (
            <img key={index} src={img} alt={`${product.name} - View ${index + 1}`} />
          ))}
        </div>
        <div className="product-info" ref={contentRef}>
          <h2>{product.name}</h2>
          <p className="price">${product.price.toFixed(2)}</p>
          <p className="category">Category: {product.category}</p>
          <button className="add-to-cart">Add to Cart</button>
          <div className="product-details">
            <h3>Product Details</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          </div>
          <div className="delivery-returns">
            <h3>Delivery & Returns</h3>
            <p>Free shipping on orders over $50. 30-day return policy.</p>
          </div>
          <footer className="product-footer">
            <p>© 2023 Spinnin' Beans. All rights reserved.</p>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Shop;
